import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ToolBox from "../components/toolbox"
import ToolForm from "../components/form"
import TemperatureForm from "../components/temperatureform"
import Content from "../components/content"
import AddsContainer from "../components/addscontainer"
import Keywords from "../components/keywords"

import translationUtils from "../utils/es-utils"
import translation from "../translations/es"

const AddsAvtive = false

const Es = ({ location }) => {
  const [formType, setFormType] = useState("measures")
  function handleFormTypeChange(type) {
    setFormType(type)
  }
  return (
    <Layout
      pathname={location.pathname}
      title={translation.title}
      description={translation.description}
      copyText={translation.copyText}
    >
      <SEO
        lang="es"
        title={translation.title}
        description={translation.description}
      />
      <ToolBox
        formType={formType}
        handleFormTypeChange={handleFormTypeChange}
        text={translation.formheader}
      >
        {formType === "measures" ? (
          <ToolForm text={translation.form} utils={translationUtils} />
        ) : (
          <TemperatureForm text={translation.temperatureform} />
        )}
      </ToolBox>
      {AddsAvtive && <AddsContainer />}
      <Keywords
        title={translation.keywords.title}
        words={translation.keywords.words}
      />
      <Content text={translation.content} utils={translationUtils} />
    </Layout>
  )
}
export default Es
