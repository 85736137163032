export default {
  ingredients: [
    { value: "1", type: "solid", group: "sugar", label: "Azúcar" },
    { value: "2", type: "solid", group: "starch", label: "Almidón de maíz" },
    { value: "3", type: "solid", group: "rice", label: "Arroz crudo" },
    { value: "4", type: "solid", group: "oats", label: "Avena" },
    {
      value: "5",
      type: "solid",
      group: "bicarbonate",
      label: "Bicarbonato de sodio, levadura química y biológica",
    },
    { value: "6", type: "solid", group: "coffe", label: "Café en polvo" },
    {
      value: "7",
      type: "solid",
      group: "nuts",
      label: "Castañas, almendras, nueces",
    },
    {
      value: "8",
      type: "solid",
      group: "chocolate",
      label: "Chocolate en polvo",
    },
    { value: "9", type: "solid", group: "coco", label: "Coco recién rallado" },
    { value: "10", type: "solid", group: "wheat", label: "Harina de trigo" },
    { value: "11", type: "solid", group: "fuba", label: "Harina de maíz" },
    {
      value: "12",
      type: "liquid",
      group: "liquid",
      label: "Líquidos (agua, leche, bebidas, yogures, aceites, etc.)",
    },
    {
      value: "13",
      type: "solid",
      group: "fats",
      label: "Mantequilla, manteca de cerdo o grasa vegetal",
    },
    { value: "14", type: "liquid", group: "honey", label: "Miel" },
    {
      value: "15",
      type: "solid",
      group: "peanutb",
      label: "Mantequilla de maní",
    },
    {
      value: "16",
      type: "solid",
      group: "powder",
      label: "Sprinkles agridulces",
    },
    { value: "17", type: "solid", group: "salt", label: "Sal común" },
    { value: "18", type: "solid", group: "cheese", label: "Queso rallado" },
  ],
  solidMeasures: [
    { value: "1", label: "cuchara de té" },
    { value: "2", label: "cuchara de postre" },
    { value: "3", label: "cuchara sopera" },
    { value: "4", label: "copa americana" },
    { value: "5", label: "taza de té" },
    { value: "6", label: "onza (oz)" },
    { value: "7", label: "gramo (g)" },
    { value: "8", label: "pound / libra (lb)" },
    { value: "9", label: "kilogramo (kg)" },
  ],

  liquidMeasures: [
    { value: "1", label: "cuchara de té" },
    { value: "2", label: "cuchara de postre" },
    { value: "3", label: "cuchara sopera" },
    { value: "4", label: "copa americana" },
    { value: "5", label: "taza de té" },
    { value: "6", label: "onça fluida (fl oz)" },
    { value: "7", label: "mililitros (ml)" },
    { value: "8", label: "quart" },
    { value: "9", label: "litros (l)" },
  ],
}
