export default {
  title: "Convertidor de medidas culinarias",
  description:
    "Elija un ingrediente y convierta las principales medidas culinarias (taza de té, copa americana, cucharas de té, postre y sopera), en gramos (g), kilogramos (kg), mililitros (ml), litros (l), así como las temperaturas (Celsius, Fahrenheit y Kelvin)",
  formheader: {
    measures: "Medidas",
    temperatures: "Temperaturas",
  },
  form: {
    ingredient: "Ingrediente",
    measure1: "Medida 1",
    measure2: "Medida 2",
    qtd: "Cantidad",
    selectPlaceholder: "Seleccione...",
  },
  temperatureform: {
    scale1: "Escala 1",
    scale2: "Escala 2",
    degrees: "Grados",
    selectPlaceholder: "Seleccione...",
  },
  keywords: {
    title: "Palabras clave",
    words:
      "convertidor de medidas * medidas culinarias * medidas de ingredientes * porciones culinarias * porciones de ingredientes * cucharadita a gramos * cucharada a gramos * taza americana a gramos * taza de té a gramos * cucharadita a taza americana * cucharada a taza americana * cucharadita a taza de té * cuchara sopera a taza de té * taza americana a ml * taza de té a ml * taza americana a litro * taza de té a litro * onzas (oz) a gramos * pounds a kilogramos * libras (lb) a kilogramos * Celsius a Fahrenheit * Celsius a Kelvin * Fahrenheit a Celsius * Fahrenheit a Kelvin",
  },
  content: {
    h2: "Tablas de conversión",
    table1: {
      decimal: "Decimal",
      fraction: "Fraccionado",
    },
    contentTable: {
      th1: "Medida",
      th2: "Cantidad",
    },
    p1:
      "Los valores decimales, que pueden aparecer como resultado de una conversión, se pueden interpretar según el siguiente esquema",
    p2:
      "Los valores utilizados como referencia para cada ingrediente se muestran en las tablas siguientes",
    p3:
      "Fuente: esta información se recopiló a través de investigaciones en sitios web y blogs especializados en cocina. El material accedido mostró pequeñas variaciones en las cantidades reportadas y se realizaron algunos ajustes para minimizarlas",
  },
  copyText: "Desarrollado por",
  page_not_found: {
    title: "Página no encontrada",
    button: "Volver a la página de inicio",
  },
}
